import React from 'react'
import { Link, graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import Img from 'gatsby-image'

import DefaultLayout from '../components/layout'
import SEO from '../components/seo'
import './blog-post.css'

import 'katex/dist/katex.min.css'
import Sidebar from '../components/sidebar'

class BlogPostTemplate extends React.Component {
  render() {
    const { data } = this.props
    const post = data.markdownRemark
    const { previous, next } = this.props.pageContext
    const recommends = data.recommends
    const tags = data.tags.group

    return (
      <DefaultLayout>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={
            data.site.siteMetadata.siteUrl +
            post.frontmatter.img.childImageSharp.fluid.src
          }
        />
        <div className="md:flex md:flex-wrap md:mb-6">
          <div className="w-full">
            {post.frontmatter.img && (
              <div className="">
                <Img
                  className=""
                  key={post.frontmatter.img.childImageSharp.fluid.src}
                  fluid={post.frontmatter.img.childImageSharp.fluid}
                />
              </div>
            )}
          </div>
          <div className="py-3 mx-3 md:mx-0">
            <header className="flex justify-center items-center header-page">
              <div className="w-full">
                <div className="mb-3 text-xs text-gray-700 mb-3">
                  <span className="mr-6">{post.frontmatter.date}</span>
                  {post.frontmatter.updatedate && (
                    <span>Update: {post.frontmatter.updatedate}</span>
                  )}
                </div>
                <h1 className="text-3xl mb-3 font-bold">{post.frontmatter.title}</h1>
                <p className="text-gray-700 text-sm mb-3">
                  {post.frontmatter.description}
                </p>
                <div className="page-tag">
                  {post.frontmatter.category && (
                    <span>
                      <Link
                        className="text-xs border border-gray-500 rounded py-1 px-2 mr-2 mb-1 text-gray-700"
                        to={`/category/${kebabCase(
                          post.frontmatter.category
                        )}/`}
                      >
                        {post.frontmatter.category}
                      </Link>
                    </span>
                  )}
                  {post.frontmatter.tags &&
                    post.frontmatter.tags.map(tag => (
                      <span key={tag}>
                        <Link
                          className="bg-gray-600 text-gray-100 text-xs py-1 px-2 mr-2 mb-1 rounded"
                          to={`/tags/${kebabCase(tag)}/`}
                        >
                          # {tag}
                        </Link>
                      </span>
                    ))}
                </div>
              </div>
            </header>
          </div>
        </div>
        <div className="">
          <div className="w-full py-6">
            <article className="article-page md:px-0">
              <div className="page-content">
                <div className="wrap-content">
                  <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </div>
              </div>
            </article>
            <div className="page-tag mb-2">
              {post.frontmatter.category && (
                <span>
                  <Link
                    className="text-xs border border-gray-500 rounded py-1 px-2 mr-2 mb-1 text-gray-700"
                    to={`/category/${kebabCase(
                      post.frontmatter.category
                    )}/`}
                  >
                    {post.frontmatter.category}
                  </Link>
                </span>
              )}
              {post.frontmatter.tags &&
                post.frontmatter.tags.map(tag => (
                  <span key={tag}>
                    <Link
                      className="bg-gray-600 text-gray-100 text-xs py-1 px-2 mr-2 mb-1 rounded"
                      to={`/tags/${kebabCase(tag)}/`}
                    >
                      # {tag}
                    </Link>
                  </span>
              ))}
            </div>
          </div>
          <Sidebar recommends={recommends} tags={tags} />
        </div>
      </DefaultLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY, MMM DD")
        updatedate(formatString: "YYYY, MMM DD")
        category
        description
        tags
        img {
          childImageSharp {
            fluid(maxWidth: 3720) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
    recommends: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { recommend: { eq: true } } }
      limit: 5
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "YYYY, MMM DD")
            title
            description
            img {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
    tags: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
